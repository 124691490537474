// Mobile navigation
$(document).on('click', '.menu-toggler', function (e) {
    e.preventDefault();
    var menu = $('.site-header ul.nav-menu');

    $(this).toggleClass('open');

    if ($(this).hasClass('open')) {
        menu.css('display', 'flex');
        menu.append('<span class="close"><i class="fa fa-close"></i></span>');
    } else {
        menu.css('display', 'none');
        menu.find('.close').remove();
    }

    $(document).on('click', 'ul.nav-menu .close', function () {
        $('ul.nav-menu').hide();
        $(this).remove();
    });
});

// Tooltips
if ($(window).width() > 600) {
    $('[data-tooltip]').qtip({
        content: {
            title: function (event, api) {
                return $(this).text();
            },
            text: function (event, api) {
                return '<img src="' + $(this).find('img').attr('src') + '" style="max-width: 220px;">';
            }
        },
        style: {
            classes: 'qtip-blue'
        },
        position: {
            target: 'mouse',
            adjust: {x: 20, y: 20}
        }
    });
}